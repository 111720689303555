import { createGlobalStyle } from 'styled-components';
import Suisse from '../assets/fonts/SuisseIntl-Regular-WebM.woff2';
import SuisseMono from '../assets/fonts/SuisseIntlMono-Regular-WebS.woff2';
import SuisseBold from '../assets/fonts/SuisseIntl-SemiBold-WebS.woff2';
import { media } from '../utils/media-queries';

const GlobalStyles = createGlobalStyle`

  * {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  html {
    font-family: var(--font-1);
    color: var(--text-color);
    font-size: 10px;
  }

  body {
    font-size: var(--fontsize-1);
    line-height: var(--line-height);
    overflow-x: hidden;
    touch-action: manipulation;
    margin-top: calc(var(--v-spacing-M) * 1.4);
    background: var(--bg-color);
  }

  /* Typography */

  @font-face {
    font-family: Suisse;
    src: url(${Suisse});
  }

  @font-face {
    font-family: Suisse Mono;
    src: url(${SuisseMono});
  }

  @font-face {
    font-family: Suisse Bold;
    src: url(${SuisseBold});
  }
  
  /* Layout */

  .spacing {
    margin: var(--v-spacing-S);
  }

  .spacing-inner {
    padding: var(--v-spacing-S);
  }

  .spacing-bottom {
    padding-bottom: var(--spacing-XL);
  }

  .spacing-outer {
    margin: calc(var(--v-spacing-S) / 2) var(--v-spacing-S);
    p {
      padding: var(--v-spacing-S);
    }
  }

  .spacing-outer-rich {
    margin: calc(var(--v-spacing-S) / 2) var(--v-spacing-S);
    padding: var(--v-spacing-S) var(--v-spacing-S) 0 var(--v-spacing-S);
    p {
      padding-bottom: var(--v-spacing-S);
    }
  }

  .spacing-outer-left {
    margin: calc(var(--v-spacing-S) / 2) var(--v-spacing-S)  calc(var(--v-spacing-S) / 2) 13vw;
    p {
      padding: var(--v-spacing-S);
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(var(--grid-cols), 1fr);
  }

  .chats {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  /* Themes */

  .yellow {
    --bg-color: var(--yellow);
    --bg-light-color: var(--yellow-light);
    --user-color: var(--pink);
  }

  .pink {
    --bg-color: var(--pink);
    --bg-light-color: var(--pink-light);
    --user-color: var(--green-light);
  }

  .white {
    --bg-color: var(--ee-color);
    --bg-light-color: var(--ee-color);
    --user-color: var(--grey);
  }

  @media ${media.M} {
    .spacing-bottom {
      padding-bottom: 30rem;
    }
  }

`;

export default GlobalStyles;
