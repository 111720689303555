import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import GlobalStyles from '../../styles/GlobalStyles';
import Normalize from '../../styles/Normalize';
import Variables from '../../styles/Variables';
import Nav from './nav';

const Layout = ({ children, className, bgSrc }) => (
  <>
    {/**
     * Styles
     */}
    <Normalize />
    <GlobalStyles />
    <Variables />
    {/**
     * Content
     */}
    <Nav />
    <main className={className}>{children}</main>
    <StyledBgGradient>
      <Img fluid={bgSrc} alt="" />
    </StyledBgGradient>
  </>
);

const StyledBgGradient = styled.div`
  position: fixed;
  bottom: -20px;
  pointer-events: none;
  z-index: -2;
  overflow: hidden;
  .gatsby-image-wrapper {
    width: 100vw;
    height: 100vh;
  }
`;

Layout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  bgSrc: PropTypes.object,
};

export default Layout;
