import { createGlobalStyle } from 'styled-components';
import { media } from '../utils/media-queries';

const Variables = createGlobalStyle`

  :root {
    /* Colors */
    --purple: #6f6ee6;
    --purple-light: #a5b0f9;
    --grey: #d6e1f5;
    --yellow: #e2e455;
    --yellow-light: #f9fa8c;
    --green-light: #58ad80;
    --pink: #fb77f1;
    --pink-light: #faacf5;
    --black: #202327;
    --black-light: #323A47;
    --white: #F0F4F8;

    --text-color: var(--black-light);
    --ee-color: var(--white);
    --bg-color: var(--purple);
    --bg-light-color: var(--purple-light);
    --user-color: var(--yellow);
    
    /* Spacing */
    --spacing-XXS: 1rem;
    --spacing-XS: 2rem;
    --spacing-S: 3rem;
    --spacing-M: 5rem;
    --spacing-L: 8rem;
    --spacing-XL: 13rem;
    --spacing-XXL: 21rem;
    /* Clamp Fallback */ --v-spacing-S: var(--spacing-XS);
    --v-spacing-S: clamp(var(--spacing-XXS), 2vw, var(--spacing-XS));
    /* Clamp Fallback */ --v-spacing-M: 4rem;
    --v-spacing-M: clamp(var(--spacing-S), 2vw, var(--spacing-M));
    /* Clamp Fallback */ --v-spacing-L: 10rem;
    --v-spacing-L: clamp(var(--spacing-L), 5vw, var(--spacing-XL));
    /* Clamp Fallback */ --v-spacing-XL: 17rem;
    --v-spacing-XL: clamp(var(--spacing-L), 5vw, var(--spacing-XXL));

    /* Fonts */
    --font-1: 'Suisse', sans-serif;
    --font-2: 'Suisse Mono', serif;
    --font-3: 'Suisse Bold', sans-serif;
    /* Clamp Fallback */ --fontsize-1: calc(30px + (60 - 30) * (100vw - 375px) / (2560 - 375));
    --fontsize-1: clamp(3rem, 5vw, 6rem);
    /* Clamp Fallback */ --fontsize-2: calc(15px + (30 - 15) * (100vw - 375px) / (2560 - 375));
    --fontsize-2: clamp(1.7rem, 2vw, 2rem);
    --line-height: 1.05;

    /* Border */
    --border-width: 1px;
    --border: var(--border-width) solid var(--text-color);

    /* Layout */
    --grid-cols: 2;
    --grid-gap: var(--spacing-S);

    /* Misc */
    --range-width: 25px;

    @media ${media.M} {
      --border-width: 2px;
    }
  }
`;

export default Variables;
