import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Favicon from '../../assets/images/favicon.png';
import Preview from '../../assets/images/preview.png';

function SEO({ lang = 'de', siteTitle = '' }) {
  const title = 'Erneuerbare Energien';
  // TODO
  const description =
    'Können wir unseren modernen Lebensstil aus erneuerbaren Energien bestreiten?';

  // TODO favicon + image fahrradfahrer
  // const [tabActive, setTabActive] = useState(true);
  // document.addEventListener('visibilitychange', (event) => {
  //   if (document.visibilityState === 'visible') {
  //     setTabActive(true);
  //   } else {
  //     setTabActive(false);
  //   }
  // });

  return (
    <Helmet titleTemplate={`${title} ${siteTitle ? `• ${siteTitle}` : ''}`}>
      <html lang={lang} />
      <title>{title}</title>
      <link rel="icon" type="image/png" href={Favicon} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta charSet="utf-8" />
      <meta name="description" content={description} />
      <meta property="og:image" content={Preview} />
      <meta property="og:title" content={title} key="ogtitle" />
      <meta property="og:site_name" content={siteTitle} key="ogsitetitle" />
      <meta
        property="og:description"
        content={description}
        key="ogdescription"
      />
    </Helmet>
  );
}

SEO.propTypes = {
  lang: PropTypes.string,
  siteTitle: PropTypes.string,
};

export default SEO;
