import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { motion, useViewportScroll } from 'framer-motion';
import PropTypes from 'prop-types';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useClientSide } from '../../hooks/useClientSide';
import Clown from '../../assets/images/clown.png';

export const NavItem = ({ to, bgColor, text, className }) => (
  <Link
    to={to}
    activeClassName="active"
    className={className}
    style={{ background: bgColor }}
  >
    <p>{text}</p>
  </Link>
);

NavItem.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
  bgColor: PropTypes.string,
  text: PropTypes.string,
};

const Nav = () => {
  const size = useWindowSize();
  const { width } = size;
  const isClient = useClientSide();
  const isDesktop = isClient && width > 768;

  const ref = useRef();

  const { scrollYProgress } = useViewportScroll();
  const [hideNav, setHideNav] = useState(0);

  useEffect(
    () => scrollYProgress.onChange(() => setHideNav(scrollYProgress.current)),
    [scrollYProgress]
  );

  return (
    <StyledHeader
      initial={{ y: 0 }}
      animate={{ y: hideNav > 0.01 ? -50 : 0 }}
      transition={{ duration: 0.3, ease: [0.6, 0.01, -0.05, 0.9] }}
    >
      <nav ref={ref}>
        <div className="gradient" />
        <div className="dots" style={{ cursor: `url(${Clown}), auto` }} />
        <NavItem to="/" text="EE" bgColor="var(--bg-color)" />
        <NavItem
          to="/buch/"
          text={!isDesktop ? 'Buch' : 'Das Buch'}
          bgColor="var(--bg-color)"
        />
        <NavItem to="/drumherum/" text="Drumherum" bgColor="var(--bg-color)" />
        <NavItem
          to="/team/"
          text={!isDesktop ? 'Team' : 'Das Team'}
          bgColor="var(--bg-color)"
        />
        <NavItem
          to="/impressum/"
          text={!isDesktop ? 'Impr.' : 'Impressum'}
          bgColor="var(--bg-color)"
          className="legal"
        />
      </nav>
    </StyledHeader>
  );
};

const StyledHeader = styled(motion.header)`
  z-index: 1;
  position: fixed;
  top: 0;
  width: 100%;
  font-size: var(--fontsize-2);

  nav {
    display: flex;
    justify-content: space-between;
    position: relative;

    .gradient {
      width: 100vw;
      height: 20px;
      position: absolute;
      bottom: 0;
      opacity: 0.35;
      background: linear-gradient(0deg, var(--black) 0%, rgba(0, 0, 0, 0) 100%);
      pointer-events: none;
    }

    .dots {
      width: auto;
      height: auto;
      padding-right: var(--v-spacing-S);
      border-right: var(--border);
      border-bottom: var(--border);
    }

    .legal {
      width: auto;
      padding-right: var(--v-spacing-S);
      border-right: 0;

      p {
        padding-right: 0;
      }
    }

    .bg {
      position: absolute;
      top: 0;
      pointer-events: none;
      opacity: 0.8;
    }

    a {
      width: 100%;
      border-right: var(--border);
      border-bottom: var(--border);
      padding: calc(var(--spacing-XXS) / 2) 0;
      text-align: left;

      p {
        padding: 0 var(--v-spacing-S);
      }
    }
  }

  .active {
    border-bottom: 0;
    z-index: 1;
  }
`;

export default Nav;
